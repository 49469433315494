import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Ferms from '../views/Ferms.vue'
import Complexes from '../views/Сomplexes.vue'
import Accessories from '../views/Accessories.vue'
import Login from '../auth/Login.vue'
import Reg from '../auth/Register.vue'
import Cabinet from '../auth/Cabinet.vue'
import AdmPanel from '../views/component/admin/Panel.vue'
import States from '../views/component/admin/States.vue'
import Menus from '../views/component/admin/Menus.vue'
import Banners from '../views/component/admin/Banners.vue'
import Account from '../views/component/user/Account.vue'
import Goods from '../views/component/user/Goods.vue'
import Basket from '../views/component/user/Basket.vue'
import Support from '../views/component/user/Support.vue'
import SupportAdmin from '../views/component/admin/Support.vue'
import Contact from '../views/component/admin/Contact.vue'
import People from '../views/component/admin/People.vue'
import BasketAdm from '../views/component/admin/Basket.vue'
import GoodsAdm from '../views/component/admin/Goods.vue'
import StatesSite from '../views/States.vue';
import Files from '../views/component/admin/Files.vue'
import Catalog from '../views/Catalog.vue'
import FarmsEdit from '../views/admin/Farms.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/farms/:id',
    name: 'фермы',
    component: Ferms
  }, 
  {
    path: '/catalog',
    name: 'каталог',
    component: Catalog
  }, 
  {
    path: '/complexes',
    name: 'комплексы',
    component: Complexes
  },      
  {
    path: '/accessories',
    name: 'Комплектующие',
    component: Accessories
  },
  {
    path: '/states/:nameMenu',
    name: 'states',
    props: true,
    component: StatesSite,
  },      
  {
    path: '/login',
    name: 'авторизация',
    component: Login
  },      
  {
    path: '/cabinet',
    name: 'кабинет',
    component: Cabinet
  },

  {
    path: '/register',
    name: 'Регистрация',
    component: Reg
  },
  {
    path: '/admin/panel',
    name: 'Панель администратора',
    component: AdmPanel
  },
  {
    path: '/admin/states',
    name: 'Добавление статей',
    component: States
  },
  {
    path: '/admin/menu',
    name: 'редактирование меню',
    component: Menus
  },
  {
    path: '/admin/banners',
    name: 'редактирование банеров',
    component: Banners
  },
  {
    path: '/admin/basket',
    name: 'редактирование заказов',
    component: BasketAdm
  }, 
  {
    path: '/admin/people',
    name: 'редактирование пользователей',
    component: People
  }, 
  {
    path: '/admin/support',
    name: 'редактирование банеров',
    component: SupportAdmin
  },    
  {
    path: '/admin/contact',
    name: 'редактирование контактов',
    component: Contact
  },
  {
    path: '/admin/goods',
    name: 'товары',
    component: GoodsAdm
  },  
  {
    path: '/admin/files',
    name: 'Files',
    component: Files
  },
  {
    path: '/admin/farms',
    name: 'farmsEdit',
    component: FarmsEdit
  },
  {
    path: '/user/account',
    name: 'Редактирование',
    component: Account
  },
  {
    path: '/user/goods',
    name: 'Товары',
    component: Goods
  },
  {
    path: '/user/basket',
    name: 'Корзина',
    component: Basket
  },
  {
    path: '/user/support',
    name: 'Поддержка',
    component: Support
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
