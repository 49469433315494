<template>
<v-row style="max-width: 1420px; margin: auto;" class="font_type">
    <v-col cols="12" md="12">
        <v-row>
            <v-col cols="12">
                <p style="font-size: 56px; font-weight: 700; line-height: 67px; letter-spacing: 0em; text-align: left; ">Контакты</p>
            </v-col>
        </v-row>
        </v-col>
    <v-col cols="12" md=4>       
        <v-row>
            <v-col cols="12" style="background-color:#FFF; margin-top: 10px;">
                <v-row>
                    <v-col>
                        <p><a href="tel:+79215953571" style="color: #F60;">+7 921 595-35-71</a>
                            </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p><a href="mailto:Info@trussforstage.ru" style="color: #F60;">Info@trussforstage.ru</a>
                            </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p>Санкт-Петербург, Пос. Парголово, ул. Ломоносова 113</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-img src="@/assets/main/socials.svg" style="height: 32px; margin-right: 10px; width: 32px; float: left;"></v-img>
                        <v-img src="@/assets/main/socials1.svg" style="height: 32px;width: 32px"></v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <order-but></order-but>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
    <v-col cols="12" md=8>
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae336dec5b753cc4813334155359f71cc2a6caa4413684a2ea5383a4cb18f44f3&amp;source=constructor" width="100%" height="365" frameborder="0"></iframe>
    </v-col>
</v-row>
</template>

<script>
import OrderBut from "../buttoms/OrderBut.vue"
export default {
    name: "test",
    data: () => ({}),
    computed: {},
        components: {
        OrderBut,
    },
    mounted() {}
};
</script>  

<style scoped>
.button_site {
    background-color: #FF6600;
    color: #fff;
}
.font_type {
    font-family: IBM Plex Sans;
}
</style>
