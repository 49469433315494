import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);



export default {
  state: {
    listMenu: [],
    listMenuEdit: [],
    ListBanners: [],
    ListUser: [],
    ListMenuState: [],
    ListStates: [],
    ListFiles: [],
    ListFarms: [],
    ListFarmsTypeGroups: [],

  },
  mutations: {
    UpdateListMenu(state, payload) {
      state.listMenu = payload;
      state.listMenuEdit.push("Главное")
      for (let index = 0; index < payload.length; index++) {
        // if(payload[index].nameMenu == "Главное") {
          state.listMenuEdit.push(payload[index].mainmenu)
        // }        
        
      }
    },
    UpdateUrlFoto(state, payload) {
      state.ListBanners = payload;
      
    },

    UpdateStates(state, payload){
      state.ListStates = payload;
    },
    UpdateListUser(state, payload){
      state.ListUser = payload;
    },
    UpdateListMenuState(state, payload) {
      state.ListMenuState = payload;
    },
    UpdateListFiles(state, payload) {
      state.ListFiles = payload;
    },
    UpdateListFarms(state, payload) {
      state.ListFarms = payload;
    },
    UpdateListFarmsTypeGroups(state, payload) {
      state.ListFarmsTypeGroups = [];
      for (let index = 0; index < payload.length; index++) {
        state.ListFarmsTypeGroups.push(payload[index].name);
        
      }
    },
  },
  actions: {
    getListMenu({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/menu/list")
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListMenu", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    
    setFotoSpec({ commit }, payload) {
      console.log(payload);
      let formData = new FormData();
      formData.append("file", payload);
      commit("onLoad", true);
      Axios.post("/api/v1/admin/foto", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (res) {
          console.log(res);
          if (res.data.message == "error") {
            alert("Ошибка загрузки файла!");
          } else {
            commit("UpdateUrlFoto", res.data.message);
            commit("onLoad", false);
          }

          console.log("SUCCESS!!");
        })
        .catch(function (res) {
          console.log(res);
          console.log("FAILURE!!");
        });
    },

    setFilesSpec({commit}, payload){
      console.log(payload);
      let formData = new FormData();
      formData.append("file", payload);
      commit("onLoad", true);
      console.log(formData);
      return new Promise((resolve, reject) => {
      Axios.post("/api/v1/admin/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (res) {
          console.log(res);
          if (res.data.message == "error") {
            alert("Ошибка загрузки файла!");
            reject([]);
          } else {
            //commit("UpdateUrlFoto", res.data.message);
            commit("onLoad", false);
            resolve(res.data.message)
          }

          console.log("SUCCESS!!");
        })
        .catch(function (res) {
          console.log(res);
          console.log("FAILURE!!");
          reject([]);
        });
      });
    },
    setListFiles({commit}, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/admin/file/list", {
        zn:payload,
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListFiles", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    setListMenu({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/menu/set", payload)
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListMenu", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });

    },
    delListMenu({commit},payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/menu/del", {
        id:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListMenu", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListBannerAdm({commit}){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/banner/get")
        .then((res) => {
          console.log(res.data);          
          commit("UpdateUrlFoto", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    setBlockFoto({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/banner/block",{
        id:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateUrlFoto", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListUserAdmin({commit}) {
      commit("onLoad", true);
      Axios.post("/api/v1/admin/user/list")
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListUser", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });

    },
    setListUser({commit}, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/admin/user/set",{
        zn:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListUser", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    
    getListMenuStates({commit}){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/menustate/get")
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListMenuState", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    setListStates({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/states/set",{
        zn:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateStates", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListState({commit}){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/states/get")
        .then((res) => {
          console.log(res.data);          
          commit("UpdateStates", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListMenuFiles({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/files/get",{
        zn:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListFiles", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListFarms({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/farms/get",{
        zn:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListFarms", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getListFarmsTypeGroup({commit}, payload){
      commit("onLoad", true);
      Axios.post("/api/v1/admin/farms/props/type/get",{
        zn:payload
      })
        .then((res) => {      
          commit("UpdateListFarmsTypeGroups", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
    setListFarms({commit}, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/admin/farms/set",{
        zn:payload
      })
        .then((res) => {
          console.log(res.data);          
          commit("UpdateListFarms", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },
  },
  getters: {
    getListMenu(state) {
      return state.listMenu;
    },
    getListMenuEdit(state) {
      return state.listMenuEdit;
    },
    getListBannerAdm(state){
      return state.ListBanners;
    },
    getListUser(state){
      return state.ListUser;
    },
    getListMenuState(state){
      return state.ListMenuState;
    },
    getStatesList(state){
      return state.ListStates;
    },
    getListFiles(state){
      return state.ListFiles;
    },
    getListFarms(state){
      return state.ListFarms;
    },
    getListFarmsTypeGroups(state){
      return state.ListFarmsTypeGroups;
    },
  },
};
