<template>
<v-app id="inspire" style="background-color: #F4F4F4">

    <!-- <v-navigation-drawer
                v-model="drawer"
                :clipped="$vuetify.breakpoint.lgAndUp"
                app
                :disable-resize-watcher = "true"
        >
            <v-list dense>
                <template v-for="item in items">
                   
                    <v-list-group
                            v-if="item.submenu"
                            :key="item.id"
                            :to="item.link"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.mainmenu }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                                v-for="(child, i) in item.submenu"
                                :key="i"
                                link
                        >
                          <v-list-group
                              v-if="child.submenu"
                              :key="child.id"
                              :to="child.link"
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                  <v-list-item-title>
                                      {{ child.mainmenu }}
                                  </v-list-item-title>
                              </v-list-item-content>
                            </template>
                             <v-list-item
                                v-for="(child1, j) in child.submenu"
                                :key="j"
                                link
                            >
                            <v-list-item-title>
                                    {{ child1.mainmenu }}
                                </v-list-item-title>
                            </v-list-item>
                          </v-list-group>
                            <v-list-item-content v-else>
                                <v-list-item-title>
                                    {{ child.mainmenu }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                            v-else
                            :key="item.mainmenu"
                            link
                            :to="item.link"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.mainmenu }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer> -->
    <!-- <v-app-bar app color="#e6b006" dark>
      
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" :disable-resize-watcher = "true" v-if="width <= 450"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-btn href="/" text color="#2c1213">
          <h3 class="logo_text" style="color: #2c1213">TRUSSFORSTAGE</h3>
        </v-btn>
      </div>

      
      
      <v-spacer></v-spacer>
      <v-toolbar v-if="width > 450" style="background-color: transparent;box-shadow:none;">

      <v-menu        
        v-for="n in items"
        :key="n.mainmenu"
        :attach="$refs.container"
        open-on-hover
        offset-y
        
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-if="n.submenu != null"
            color="#2c1213"
            v-bind="attrs"
            text
            v-on="on"
          >
            {{ n.mainmenu }}
          </v-btn>
          <v-btn v-else color="#2c1213" v-bind="attrs" text :to="n.link">
            {{ n.mainmenu }}
          </v-btn>
        </template>
        <v-list v-for="(v, i) in n.submenu" :key="i">
          <v-list-item v-if="v.submenu == undefined" :to="{ name: 'states', params: { nameMenu: v.link } }">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-title>{{ v.mainmenu }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-if="v.submenu != undefined" :value="true" sub-group>
            <template v-slot:activator>
              <v-hover v-slot:default="{ }">
              <v-list-item-title>{{ v.mainmenu }}</v-list-item-title>
              </v-hover>
            </template>
            <v-list-item v-for="(b, j) in v.submenu" :key="j" :to="{ name: 'states', params: { nameMenu: b.link } }">
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-title>{{ b.mainmenu }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu> 
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-btn v-if="Auth" href="/cabinet" text color="#2c1213">
        <span class="mr-2">В кабинет</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn v-else href="/login" text color="#2c1213">
        <span class="mr-2">Вход</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>-->

    <v-main>
      
        <router-view />
    </v-main>
    <v-footer style="background-color:#FFFFFF">
      <footer-panel />

    </v-footer>
</v-app>
</template>

<script>
import FooterPanel from "./components/footer/Footer_panel.vue"
export default {
    name: "App",

    data: () => ({
        drawer: false,
        width: 0,
        
    }),
    components: {
      FooterPanel
    },
    computed: {
        Auth() {
            return this.$store.getters.getAuthUser;
        },
        contact() {
            return this.$store.getters.getContact;
        },
        banners() {
            return this.$store.getters.getBannersSite;
        },
        items() {
            return this.$store.getters.getListMenuMain;
        }
    },
    mounted() {
        this.$store.dispatch("getListMenuSite");
        this.$store.dispatch("getContactSite");
        this.$store.dispatch("getBannersSite");
        this.updateWidth;
    },
    created() {

        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
    },
    methods: {
        updateWidth() {
            this.width = window.innerWidth;
        },
    }
};
</script>

<style scoped>
.logo_text {
    color: #2c1213;
}
#inspire {
  max-width: 1920px;
  margin: auto;
}
body {
    background-color: #F4F4F4;
}

div {
    background-color: inherit;
}
</style>
