import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

Vue.use(Vuex);

class User {
  constructor(id) {
    this.id = id;
  }
}

export default {
  state: {
    user: null,
    listMenuUser: [],
    AuthUser: false,
    Firm: [],
    ListSupportMessage: [],
    ListContact: [],
    ListBannersSite: [],
    ListMenuMain: [],
    ListObjectMainProps: [],
    ListObjectDopProps: [],
    InfoObject: [],
  },
  mutations: {
    setUserAuth(state, payload) {
      state.user = payload;
    },
    setMenuMain(state, payload) {
      state.ListMenuMain = payload;
    },
     setInfoObject(state, payload) {
      state.InfoObject = payload.inf[0];
      state.ListObjectMainProps = payload.mProps;
      state.ListObjectDopProps = payload.dProps;
    },
    updateContact(state, payload) {
      state.ListContact = payload;
    },
    setFirm(state, payload) {
      state.Firm = {
        name: payload.nameCompany,
        inn: payload.inn,
        email: payload.email,
        phone: payload.phone,
      };
    },
    updateBannersSite(state, payload) {
      state.ListBannersSite = payload;
    },
    setMenuUser(state, payload) {
      if (payload == 0) {
        state.listMenuUser = [
          { title: "Товары", icon: "mdi-home-city", to: "/user/goods" },
          { title: "Мой аккаут", icon: "mdi-account", to: "/user/account" },
          { title: "Корзина", icon: "mdi-basket", to: "/user/basket" },
          { title: "Поддержка", icon: "mdi-face-agent", to: "/user/support" },
        ];
      } else {
        state.listMenuUser = [
          {
            title: "Статистика",
            icon: "mdi-transit-transfer",
            to: "/admin/panel",
          },
          { title: "Меню", icon: "mdi-home-city", to: "/admin/menu" },
          { title: "Пользователи", icon: "mdi-account", to: "/admin/people" },
          { title: "Продукция", icon: "mdi-sale-outline", to: "/admin/goods" },
          { title: "Статьи", icon: "mdi-newspaper", to: "/admin/states" },
          { title: "Файлы", icon: "mdi-newspaper", to: "/admin/files" },
          { title: "Контакты", icon: "mdi-contacts", to: "/admin/contact" },
          { title: "Заказы", icon: "mdi-basket", to: "/admin/basket" },
          { title: "Банеры", icon: "mdi-basket", to: "/admin/banners" },
          { title: "Фермы", icon: "mdi-basket", to: "/admin/farms" },
          { title: "Поддержка", icon: "mdi-face-agent", to: "/admin/support" },
        ];
      }
      console.log(state.listMenuUser);
    },
    setAuth(state, payload) {
      state.AuthUser = payload;
    },
    updateSupportMessage(state, payload) {
      state.ListSupportMessage = payload;
    },
  },
  actions: {
    OnSaveFirmData({ commit }, payload) {
      console.log(payload);
      commit("onLoad", true);
      Axios.post("/api/v1/user/data/update", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.status != "ok") {
            alert("Не удалось сохранить!");
          } else {
            commit("onLoad", false);
            commit("setFirm", res.data.message);
            localStorage.setItem(
              "trassforstage-user-email",
              res.data.message[0].email
            );
            localStorage.setItem(
              "trassforstage-user-name",
              res.data.message[0].nameCompany
            );
            localStorage.setItem(
              "trassforstage-user-phone",
              res.data.message[0].phone
            );
            localStorage.setItem(
              "trassforstage-user-inn",
              res.data.message[0].inn
            );
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getInfoObject({commit}, payload){
 console.log(payload);
      commit("onLoad", true);
      Axios.post("/api/v1/object/info", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          commit('setInfoObject', res.data.message)
        })
        .catch((res) => {
          console.log(res);
        });
    },
    

    getListMainClient({ commit }, payload) {
      console.log(payload);
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/user/login", {
          zn: payload,
        })
          .then((res) => {
            console.log(res.data);
            if (res.data.message.message != "Успешный вход в систему.") {
              alert("Не верный логин и пароль!");
            } else {
              commit("onLoad", false);
              commit("setMenuUser", res.data.message.status);
              commit("setFirm", res.data.message);
              commit("setAuth", true);
              commit("setUserAuth", new User(res.data.message.jwt));
              Axios.defaults.headers.common["Authorization"] =
                res.data.message.jwt;
              localStorage.setItem(
                "trassforstage-user-token",
                res.data.message.jwt
              );
              localStorage.setItem(
                "trassforstage-user-status",
                res.data.message.status
              );
              localStorage.setItem(
                "trassforstage-user-email",
                res.data.message.email
              );
              localStorage.setItem(
                "trassforstage-user-name",
                res.data.message.nameCompany
              );
              localStorage.setItem(
                "trassforstage-user-phone",
                res.data.message.phone
              );
              localStorage.setItem(
                "trassforstage-user-inn",
                res.data.message.inn
              );
              resolve(true);
            }
          })
          .catch((res) => {
            console.log(res);
            reject(res);
          });
      });
    },
    autoLoginUser({ commit }) {
      commit("onLoad", true);
      Axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        "trassforstage-user-token"
      );
      commit(
        "setUserAuth",
        new User(localStorage.getItem("trassforstage-user-token"))
      );
      commit("setMenuUser", localStorage.getItem("trassforstage-user-status"));
      let t = {
        nameCompany: localStorage.getItem("trassforstage-user-name"),
        inn: localStorage.getItem("trassforstage-user-inn"),
        email: localStorage.getItem("trassforstage-user-email"),
        phone: localStorage.getItem("trassforstage-user-phone"),
      };
      commit("setFirm", t);
      commit("setAuth", true);
      commit("onLoad", false);
      console.log("авто вход успешен!");
    },
    onUpdatePass({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/user/pass", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.status != "ok") {
            alert("Не удалось сохранить!");
          } else {
            alert(res.data.message);
            commit("onLoad", false);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    onExitUser({ commit }) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        commit("onLoad", false);
        commit("setAuth", false);
        commit("setUserAuth", null);
        localStorage.removeItem("trassforstage-user-token");
        localStorage.removeItem("trassforstage-user-status");
        localStorage.removeItem("trassforstage-user-email");
        localStorage.removeItem("trassforstage-user-name");
        localStorage.removeItem("trassforstage-user-inn");
        localStorage.removeItem("trassforstage-user-phone");
        if (localStorage.getItem("trassforstage-user-token")) {
          reject(false);
        } else {
          resolve(true);
        }
      });
    },
    getMenuUser({ commit }) {
      commit("setMenuUser");
    },
    setNewUser({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/user/register", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.message == "good") {
            alert("вы успешно зарегистрированы!");
          } else {
            alert("Регистрация не удалась!");
          }
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    onSaveMessageSupport({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/user/support/new", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          commit("updateSupportMessage", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    getListMessageUser({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/user/support/list")
        .then((res) => {
          console.log(res.data);
          commit("updateSupportMessage", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    getListMessageAdmin({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/admim/support/list")
        .then((res) => {
          console.log(res.data);
          commit("updateSupportMessage", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    getCloseStatusMessage({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/admim/support/close", {
        id: payload,
      })
        .then((res) => {
          console.log(res.data);
          commit("updateSupportMessage", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    getListMenuSite({ commit }) {
      commit("onLoad", true);
      console.log('1111');
        Axios.post("/api/v1/menu/list")
          .then((res) => {
            console.log('1112');
            console.log(res.data);            
            commit("setMenuMain", res.data.message);
            commit("onLoad", false);
          })
          .catch(() => {
            console.log("error");
            console.log('1113');
            commit("onLoad", false);
            commit("setMenuMain", []);
          });
    },
    getStateListId({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/state/id", {
          zn: payload
        })
          .then((res) => {
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            commit("onLoad", false);
            reject([]);
          });
      });
    },
    setContact({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/admin/contact/set", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    getListContact({ commit }) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/admin/contact/get")
          .then((res) => {
            console.log("all contact");
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            commit("onLoad", false);
            reject([]);
          });
      });
    },
    getContactSite({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/contact/get")
        .then((res) => {
          console.log("hello");
          console.log(res.data);
          commit("updateContact", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
    getHistoryMessage({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/user/support/history/list", {
          id: payload,
        })
          .then((res) => {
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject([]);
            commit("onLoad", false);
          });
      });
    },
    getBannersSite({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/banners/get")
        .then((res) => {
          console.log("banners");
          console.log(res.data);
          commit("updateBannersSite", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
          commit("onLoad", false);
        });
    },
  },
  getters: {
    getAuthUser(state) {
      return state.AuthUser;
    },
    getMenuUser(state) {
      return state.listMenuUser;
    },
    getNameFirm(state) {
      return state.Firm.name;
    },
    getFullFirm(state) {
      return state.Firm;
    },
    getUserMessage(state) {
      return state.ListSupportMessage;
    },
    getContact(state) {
      return state.ListContact;
    },
    getBannersSite(state) {
      return state.ListBannersSite;
    },
    getListMenuMain(state) {
      return state.ListMenuMain;
    },
    getInfoObject(state) {
      return state.InfoObject
    },
    getListObjectMainProps(state) {
      return state.ListObjectMainProps
    },
    getListObjectDopProps(state) {
      return state.ListObjectDopProps
    }
  },
};
