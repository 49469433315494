<template>
    <v-row id="main_div_text">
        <v-col cols="12" md=5>
            <v-row style="margin-top: 10px;">
                <v-col cols="12" md="4" style="margin-top: 20px;">
                    <v-img src="@/assets/main/logo.svg" style="width: 140px; height: 70px;"></v-img>
                </v-col>
                <v-col cols="12" md="8" style="margin-top: 31px;">
                    <menu-but />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="main_text">
                    <v-row>
                        <v-col cols="12">
                            <p id="a1">Truss for Stage</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p id="a2">Аллюминевые фермы</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <p id="a3"> Инновационный лидер отрасли в области проектирования ипроизводства стропильных и
                                промежуточных систем </p>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md=7 id="img_main">
            <v-img lazy-src="@/assets/main/main_lazy.png" src="@/assets/main/main_lazy.png" style="width: 100%; "></v-img>
        </v-col>
    </v-row>
</template>

<script>
import MenuBut from "../buttoms/MenuBut.vue"
export default {
    name: "test",
    data: () => ({

    }),
    computed: {},
    components: {
        MenuBut
    },
    mounted() { }
};
</script>  

<style scoped>
.button_site {
    background-color: #FF6600;
    color: #fff;
}

.cataloge_style:hover {
    color: #FF6600;
    cursor: pointer;
}

.main_text {
    margin-top: 166px;
}

#a1 {
    font-family: IBM Plex Sans;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #999999;
}

#a3 {
    font-family: IBM Plex Sans;
    font-size: 62px;
    font-weight: 700;
    line-height: 74px;
    letter-spacing: 0em;
    text-align: left;
    width: 580px;
    height: 148px;
}

#a3 {
    font-family: IBM Plex Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    width: 420px;
    height: 75px;
}

@media screen and (max-width: 450px) {
    .main_text {
        margin-top: 360px;
    }

    #a1 {
        font-family: IBM Plex Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

    }

    #a2 {
        font-family: IBM Plex Sans;
        font-size: 42px;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;

    }

    #a3 {
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;

    }

    #img_main {
        position: relative;
        top: -660px
    }

    #main_div_text {
        height: 920px;
    }
    

}
</style>
