<template>
 <v-row style="max-width: 1420px; margin: auto;">
    <v-col cols="12" md=12>
        <h2 style="font-family: IBM Plex Sans; font-size: 56px; font-weight: 700; line-height: 67px; letter-spacing: 0em; text-align: left; ">Каталог продукции</h2>
    </v-col>
     <v-col cols="12" md=12>
         <v-row >
             <v-col cols="12" md="4"  v-for="(n, i) in items" :key="i">
                <Elem :title="n.nameObj" :img="n.img" :link="n.id" />
             </v-col>
         </v-row>
          
        
     </v-col>
 </v-row>
</template>

 
<script>
import Elem from './element/Goods.vue'
 export default {
     name: "test",
     data: () => ({}),
     computed: {
      items(){
            return this.$store.getters.getListMenuMain; 
        }
     },
     components: {
        Elem
     },
     mounted() {
      this.$store.dispatch("getListMenuSite")
     }
 }; </script>  
<style scoped>
 .button_site {
     background-color: #FF6600;      color: #fff;  }
</style>
