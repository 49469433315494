<template>
    <v-container>
        <v-row justify="center" width="100%">
            <v-dialog v-model="dialog" persistent max-width="1300">
                <v-card>
                    <v-card-title class="text-h5">
                        Ферма
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Название" required v-model="data.name"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select :items="typeGroup" label="тип фермы" v-model="data.type" required></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea name="input-7-1" label="Описание фермы" value="Введите описание фермы"
                                        v-model="data.info"></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-file-input truncate-length="15" label="Загрузить картинку"
                                        accept="image/png, image/jpeg, image/bmp"></v-file-input>
                                </v-col>
                                <v-col cols="12">
                                    <v-file-input truncate-length="15" label="документ спецификация"
                                        accept="application/pdf"></v-file-input>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field label="Сплав" required v-model="data.splav"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field label="Основная труба" required v-model="data.mainT"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field label="Премычки" required v-model="data.perem"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field label="Тип соединения" required v-model="data.typeL"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    Стандартная доступная длина и кодировка
                                </v-col>
                                <v-col cols="6" md="5">
                                    <v-text-field label="Метры" required v-model="dataArtc.legth"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="5">
                                    <v-text-field label="Артикул" required v-model="dataArtc.acticle"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-btn @click="addArticle">+</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="headers_legh" :items="data.listArticl">
                                        <template v-slot:item.activ="{ item }">
                                            <v-btn elevation="2" small @click="deleteArticle(item.acticle)"
                                                style="margin-bottom: 5px;" title="Удалить">
                                                <v-icon>
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="12">
                                    Допустимая нагрузка
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="ПЯДЬ, м" required v-model="dataDop.piad"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="УДЛ, кг/м" required v-model="dataDop.ydl"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="ОТКЛОНЕНИЕ, мм" required v-model="dataDop.otklYpd"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label=".CPL, кг" required v-model="dataDop.cpl"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="ОТКЛОНЕНИЕ, мм" required v-model="dataDop.otlCPL"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="ТПЛ, кг" required v-model="dataDop.tpl"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-text-field label="КПЛ, кг" required v-model="dataDop.kpl"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-text-field label="ФПЛ, кг" required v-model="dataDop.fpl"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-text-field label="ПЯДЬ, общий вес" required
                                        v-model="dataDop.piadshare"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="3">
                                    <v-btn color="green darken-1" title="добавить" @click="addTableProp">
                                        +
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="headersTables1" :items="data.listProbs">
                                        <template v-slot:item.fff="{ item }">
                                            <v-btn elevation="2" small @click="deleteProb(item.piad)"
                                                style="margin-bottom: 5px;" title="Удалить">
                                                <v-icon>
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="12">
                                    <hr />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn color="green darken-1" text> Удалить </v-btn> -->
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog = false">
                            Отмена
                        </v-btn>
                        <v-btn color="green darken-1" text @click="onSave">
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-col cols="3">
                <menus></menus>
            </v-col>
            <v-col cols="9">
                <v-row>
                    <v-col cols="12" md="6">
                        <h1>Фермы</h1>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn @click="OpenNewDialog">
                            Добавить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                    hide-details></v-text-field>
                            </v-card-title>
                            <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
                        </v-card>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Menus from "../../views/component/Menus.vue";
export default {
    name: "App",

    data: () => ({
        search: '',
        dialog: false,
        data: {
            id: 0,
            name: '',
            type: '',
            info: '',
            splav: '',
            mainT: '',
            perem: '',
            typeL: '',
            listArticl: [],
            listProbs: [],
        },
        dataArtc: {
            legth: 0,
            acticle: '',
        },
        dataDop: {
            piad: 0,
            ydl: 0,
            otklYpd: 0,
            cpl: 0,
            otlCPL: 0,
            tpl: 0,
            kpl: 0,
            fpl: 0,
            piadshare: 0,
        },
        headers: [
            { text: 'ид', value: 'id' },
            {
                text: 'Название фермы',
                value: 'nameObj',
            },
            { text: 'Тип фермы', value: 'tpgp' },
            { text: 'Описание', value: 'info' },
        ],
        headers_legh: [

            {
                text: 'метры',
                class: 'my-header-style',
                value: 'legth',
            },
            {
                text: 'артикул', value: 'acticle',
                class: 'my-header-style'
            },
            {
                text: 'действия', value: 'activ',
                class: 'my-header-style'
            },
        ],
        headersTables1: [
            {
                text: 'ПЯДЬ, м',
                value: 'piad',
                class: 'my-header-style'
            },
            {
                text: 'УДЛ, кг/м',
                value: 'ydl',
                class: 'my-header-style'
            },
            {
                text: 'ОТКЛОНЕНИЕ, мм',
                value: 'otklYpd',
                class: 'my-header-style'
            },
            {
                text: '.CPL, кг',
                value: 'cpl',
                class: 'my-header-style'
            },
            {
                text: 'ОТКЛОНЕНИЕ, мм',
                value: 'otlCPL',
                class: 'my-header-style'
            },
            {
                text: 'ТПЛ, кг',
                value: 'tpl',
                class: 'my-header-style'
            },
            {
                text: 'КПЛ, кг',
                value: 'kpl',
                class: 'my-header-style'
            },
            {
                text: 'ФПЛ, кг',
                value: 'fpl',
                class: 'my-header-style'
            },
            {
                text: 'ПЯДЬ, общий вес',
                value: 'piadshare',
                class: 'my-header-style'
            },
            {
                text: 'действие',
                value: 'fff',
                class: 'my-header-style'
            },
        ],

    }),
    computed: {
        desserts() {
            return this.$store.getters.getListFarms;
        },
        typeGroup() {
            return this.$store.getters.getListFarmsTypeGroups;
        }
    },
    components: {
        Menus,
    },
    mounted() {
        this.$store.dispatch("getListFarms");
        this.$store.dispatch("getListFarmsTypeGroup");
    },
    methods: {
        OpenNewDialog() {
            this.dialog = true;
        },
        onSave() {
            this.$store.dispatch("setListFarms", this.data);
        },
        addArticle() {
            if (this.dataArtc.legth != 0 && this.dataArtc.acticle != '') {
                this.data.listArticl.push(JSON.parse(JSON.stringify(this.dataArtc)));
                this.dataArtc.legth = 0;
                this.dataArtc.acticle = '';
            }
        },
        deleteArticle(t) {
            for (let index = 0; index < this.data.listArticl.length; index++) {
                if (this.data.listArticl[index].acticle == t) {
                    if (confirm("Вы уверены что хотите удалить артикул: " + t + "?")) {
                        this.data.listArticl.splice(index, 1);
                    }
                }

            }
        },
        addTableProp() {
            if (this.dataDop.piad != 0 && this.dataDop.ydl != 0 && this.dataDop.otklYpd != 0) {
                this.data.listProbs.push(JSON.parse(JSON.stringify(this.dataDop)));
                this.dataDop.piad = 0;
                this.dataDop.ydl = 0;
                this.dataDop.otklYpd = 0;
                this.dataDop.cpl = 0;
                this.dataDop.otlCPL = 0;
                this.dataDop.tpl = 0;
                this.dataDop.kpl = 0;
                this.dataDop.fpl = 0;
                this.dataDop.piadshare = 0;
            }
        },
        deleteProb(t) {
            console.log(t);
            console.log(this.data.listProbs);
            for (let index = 0; index < this.data.listProbs.length; index++) {
                if (this.data.listProbs[index].piad == t) {
                    if (confirm("Вы уверены что хотите удалить запись?")) {
                        this.data.listProbs.splice(index, 1);
                    }
                }

            }
        }
    }
};
</script>