import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import Axios from 'axios';
import VueMask from 'v-mask';

Vue.config.productionTip = false
Vue.use(VueMask)


new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
  created () {
    const token = localStorage.getItem('trassforstage-user-token')
    Axios.defaults.baseURL = 'http://bot.x-or.ru';
    //Axios.defaults.baseURL = 'http://tru2517666.nichost.ru/';
    //Axios.defaults.baseURL = 'http://trass.local/';
     if (token) {
       this.$store.dispatch('autoLoginUser')
     } 
  }
}).$mount('#app')
