<template>

<v-contaner>
    <v-row>
    <div>
    <v-breadcrumbs :items="items"></v-breadcrumbs>

  </div>
</v-row>
    <v-row>
        <v-col cols="12" md="6">
            <a href="/">
<v-img src="@/assets/main/logo.svg" style="width: 140px; height: 70px;"></v-img>
            </a>
            
        </v-col>
        <v-col cols="12" md="6">
           <menu-but />
        </v-col>
    </v-row>
<v-row>
    <catalog-items></catalog-items>
</v-row>
</v-contaner>
</template>

<script>
import CatalogItems from '../components/cataloge/Cataloge.vue'
import MenuBut from '../components/buttoms/MenuBut.vue'
export default {
    name: "test",
    data: () => ({
        
    }),
    components:{
        CatalogItems, MenuBut
    },
    computed: {
        
    },
    mounted() {
        
    }
};
</script>  

<style scoped>
.button_site {
    background-color: #FF6600;
    color: #fff;
}
.cataloge_style:hover {
    color: #FF6600;
    cursor: pointer;
}
</style>
