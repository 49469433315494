<template>
    <div class="Farms" style="text-align: center">
        <v-row>
            <div>
                <v-breadcrumbs :items="items"></v-breadcrumbs>

            </div>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <a href="/">
                    <v-img src="@/assets/main/logo.svg" style="width: 140px; height: 70px;"></v-img>
                </a>

            </v-col>
            <v-col cols="12" md="6">
                <menu-but />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-img :src="'http://bot.x-or.ru/public/images/frems/' + info.img"
                    style="width: 460px;height: 460px;"></v-img>
            </v-col>
            <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 42px; font-weight: 700; line-height: 59px; letter-spacing: 0em; text-align: left; ">
                            {{ info.nameObj }}
                        </p>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 16px; font-weight: 400; line-height: 22px; letter-spacing: 0em; text-align: left; ">
                            {{ info.info }}
                        </p>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-btn
                            style="background-color: #FF6600; color: #fff; font-family: IBM Plex Sans; font-size: 18px; font-weight: 500; line-height: 22px; letter-spacing: 0em; text-align: left; ">
                            Документ спецификация
                        </v-btn>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <p
                    style="font-family: IBM Plex Sans; font-size: 42px; font-weight: 700; line-height: 50px; letter-spacing: 0em; text-align: left; ">
                    Стандартная доступная длина и кодировка</p>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1"></v-data-table>
            </v-col>
            <v-col cols="12">
                <p
                    style="font-family: IBM Plex Sans; font-size: 16px; font-weight: 400; line-height: 21px; letter-spacing: 0em; text-align: left; ">
                    Другие длины доступны по запросу</p>
            </v-col>
            <v-col cols="12" v-if="propsdop.tech.length != 0">
                <v-row>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 42px; font-weight: 700; line-height: 50px; letter-spacing: 0em; text-align: left; ">
                            Технические характеристики – Серия {{ info.nameObj }}</p>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6" class="header_tech">Сплав</v-col>
                            <v-col cols="12" md="6" class="tech_pol">{{ propsdop.tech[0].alloy }}</v-col>
                            <v-col cols="12" md="6" class="header_tech">Основная труба</v-col>
                            <v-col cols="12" md="6" class="tech_pol">{{ propsdop.tech[0].mainAccords }}</v-col>
                            <v-col cols="12" md="6" class="header_tech">Премычки</v-col>
                            <v-col cols="12" md="6" class="tech_pol">{{ propsdop.tech[0].Dkernel }}</v-col>
                            <v-col cols="12" md="6" class="header_tech">Тип соединения</v-col>
                            <v-col cols="12" md="6" class="tech_pol">{{ propsdop.tech[0].sysAiming }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-if="info.nameObj!='D30S — Плоская ферма'">
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 42px; font-weight: 700; line-height: 50px; letter-spacing: 0em; text-align: left; ">
                            {{ info.nameObj }} - Допустимая нагрузка
                            </p>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 24px; font-weight: 500; line-height: 29px; letter-spacing: 0em; text-align: left; ">
                            МАКСИМАЛЬНО ДОПУСТИМЫЕ ТОЧЕЧНЫЕ НАГРУЗКИ</p>
                    </v-col>
                    <v-col cols="12">
                        <v-data-table :headers="headersTables1" :items="desserts" :items-per-page="5"
                            class="elevation-1"></v-data-table>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 42px; font-weight: 700; line-height: 50px; letter-spacing: 0em; text-align: left; ">
                            {{ info.nameObj }} - Допустимая нагрузка
                            (Пролет поддерживается на верхнем поясе)</p>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 24px; font-weight: 500; line-height: 29px; letter-spacing: 0em; text-align: left; ">
                            Равномерное распределение Груз</p>
                    </v-col>
                    <v-col cols="12">
                        <v-data-table :headers="headersTables1" :items="desserts" :items-per-page="5"
                            class="elevation-1"></v-data-table>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 16px; font-weight: 400; line-height: 21px; letter-spacing: 0em; text-align: left; ">
                            Пролеты должны поддерживаться на каждом конце.
                            Грузы должны подвешиваться только к нижнему поясу.</p>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 42px; font-weight: 700; line-height: 50px; letter-spacing: 0em; text-align: left; ">
                            D30S - Допустимая нагрузка
                            (Верхние пояса поддерживаются сбоку через каждые 2 метра)</p>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 24px; font-weight: 500; line-height: 29px; letter-spacing: 0em; text-align: left; ">
                            Равномерное распределение Груз</p>
                    </v-col>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="desserts" :items-per-page="5"
                            class="elevation-1"></v-data-table>
                    </v-col>
                    <v-col cols="12">
                        <p
                            style="font-family: IBM Plex Sans; font-size: 16px; font-weight: 400; line-height: 21px; letter-spacing: 0em; text-align: left; ">
                            Пролеты должны поддерживаться на каждом конце.
                            Грузы должны подвешиваться только к нижнему поясу.</p>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
        <v-row>
            <v-col cols="12">
                <p
                    style="font-family: IBM Plex Sans; font-size: 56px; font-weight: 700; line-height: 67px; letter-spacing: 0em; text-align: left; ">
                    Другие продукты</p>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="2" v-for="(n, i) in drGoods" :key="i">
                        <Elem v-if="n.id != $route.params.id" :title="n.nameObj" :img="n.img" :link="n.id" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Elem from '../components/cataloge/element/Goods.vue'
import MenuBut from '../components/buttoms/MenuBut.vue'
export default {
    name: "test",
    data: () => ({
        headers: [{
            text: 'Метры',
            align: 'start',
            sortable: false,
            value: 'metres',
            class: 'my-header-style'
        },
        {
            text: 'Артикул',
            value: 'article',
            class: 'my-header-style'
        },
        ],

        headersTables1: [
            {
                text: 'ПЯДЬ, м',
                value: 'metres',
                class: 'my-header-style'
            },
            {
                text: 'УДЛ, кг/м',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: 'ОТКЛОНЕНИЕ, мм',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: '.CPL, кг',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: 'ОТКЛОНЕНИЕ, мм',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: 'ТПЛ, кг',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: 'КПЛ, кг',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: 'ФПЛ, кг',
                value: 'article',
                class: 'my-header-style'
            },
            {
                text: 'ПЯДЬ, общий вес',
                value: 'article',
                class: 'my-header-style'
            },
        ],



        items: [{
            text: 'Dashboard',
            disabled: false,
            href: 'breadcrumbs_dashboard',
        },
        {
            text: 'Link 1',
            disabled: false,
            href: 'breadcrumbs_link_1',
        },
        {
            text: 'Link 2',
            disabled: true,
            href: 'breadcrumbs_link_2',
        },
        ],
    }),
    computed: {
        info() {
            return this.$store.getters.getInfoObject;
        },
        desserts() {
            return this.$store.getters.getListObjectMainProps
        },
        propsdop() {
            return this.$store.getters.getListObjectDopProps
        },
        drGoods() {
            return this.$store.getters.getListMenuMain;
        }
    },
    components: {
        Elem,
        MenuBut
    },
    mounted() {
        console.log(this.$route.params.id);
        this.$store.dispatch("getInfoObject", this.$route.params.id)
    },
    updated() {
        this.$store.dispatch("getInfoObject", this.$route.params.id)
    }
}
</script>

<style>
.my-header-style {
    background: #FF6600;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    height: Hug (58px);
    padding: 20px;
    border-radius: 6px, 6px, 0px, 0px;
    gap: 12px;

}

.cataloge_style:hover {
    color: #FF6600;
    cursor: pointer;
}

.header_tech {
    background: #FF6600;
    border: solid 1px grey;
    text-align: left;
    color: white;
}

.tech_pol {
    border: solid 1px grey;

    text-align: left;
}</style>
