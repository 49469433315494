<template>
  <div class="home">
     <main-header style="margin-left: 10px;"></main-header>
    <innov />
    <!-- <cataloge /> -->
    <contact />
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from '../components/site/Main.vue'
import Innov from '../components/site/Inov.vue'
// import Cataloge from '../components/cataloge/Cataloge.vue'
import Contact from '../components/contact/Contact.vue'

export default {
  name: 'Home',
  components: {
    MainHeader,Innov, Contact
  }
}
</script>
