<template>
    <v-row>
        <v-col cols="12" md=12>
            <v-img :src="`http://bot.x-or.ru/public/images/frems/${img}`" style="width: 460px;height: 460px;"></v-img>

        </v-col>
        <v-col cols="12" md=12>
            <p><b>{{title}}</b></p>

        </v-col>
        <v-col cols="12" md=12>
            <v-btn
                style="background-color: #FF6600; color: #fff; font-family: IBM Plex Sans; font-size: 14px; font-weight: 500; line-height: 22px; letter-spacing: 0em; text-align: left;width: 160px; height: 54px;"
                :to="'/farms/'+link">Подробнее</v-btn>

        </v-col>
    </v-row>
</template>

 
<script>
export default {
    name: "element",
    props: {
        title: String,
        img: String,
        link: String,

    },
    data: () => ({}),
    computed: {},
    mounted() { }
};
</script>  
<style scoped>
.button_site {
    background-color: #FF6600;
    color: #fff;
}
</style>
